import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    min-height: ${props=>props.dheight?props.dheight:"600px"};
    width:100%;
    padding-top:1rem;
    padding-bottom:1rem;
    display:flex;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-end;
        
    }

    @media (min-width: 1024px) { 
        
    }

    background-color: ${props=>props.bg?props.bg:""};

`;

const LeftSide = styled.div`
position: relative;
    width:100%;
    @media (min-width: 768px) {
        width:69%;
    }
`;


const RightSide = styled.div`
position: relative;
    width:100%;
    @media (min-width: 768px) {
        width:220px;
        min-height: 700px;       
    }
`;



const TwoStages = ({ content = <><h1>Content</h1></>, rightContent=<></>,bg="",dheight="" }) => {
    return (
        <Wrapper bg={bg} dheight={dheight}>
            <LeftSide>
                {content}
            </LeftSide>
            <RightSide>
                {rightContent}
            </RightSide>
        </Wrapper>

    )
}

export default TwoStages;