import ImgLib from "./ImgLib";

const Courses = {
  data: [
    
  ],
};

export default Courses;

/**{   
    "id": 1,
    "title":"webinar 1",
    "date":"Fecha 1",
    "time":"Hora 1",
    "image":"vacio",
    "description":"Es un evento tipo webinar perto es numero 1",
    "link":"/",
},**/
